import { render_date } from "./render-helpers.js";
import $ from "jquery"

const PreviewTablesConfigs = {
    last_jobs: {
        ajax: "/api/v1.0/jobs",
        pageLength: 15,
        lengthMenu: [15, 30],
        lengthChange: true,
        autoWidth: true,
        processing: true,
        pagingType: "simple",
        columnDefs: [
            {
                targets: "_all",
                defaultContent: "",
            },
            {
                targets: [0],
                visible: true,
                responsivePriority: 1,
                title: "Date",
                type: "str",
                data: "created",
                width: "40%",
                render: render_date(),
            },
            {
                targets: [1],
                visible: true,
                responsivePriority: 1,
                title: "Pipeline",
                type: "str",
                data: "pipeline_name",
                width: "40%",
            },
            {
                targets: [2],
                responsivePriority: 2,
                visible: true,
                className: "text-right",
                title: "Actions",
                width: "20%",
                data: null,
                render: (_data, type, _full, _meta) => {
                    if (type !== "display") {
                        return null;
                    }
                    const html = [];
                    html.push(
                        '<a href="#" class="btn btn-primary editor_preview">',
                    );
                    html.push('<span class="fa  fa-eye"></span>');
                    html.push("</a>");
                    return html.join("");
                },
            },
        ],
        order: [[1, "desc"]],
        select: false,
        responsive: true,
        serverSide: true,
    },
};

function updateUserJobsTable() {
    const table = $("#jobs_table").DataTable(PreviewTablesConfigs.last_jobs);
    $("#jobs_table tbody").on("click", "a.editor_preview", function (e) {
        const tr = $(this).closest("tr");
        const data = table.row(tr).data() || table.row(e.target).data();
        window.location = `/user/preview?kiosk=True&hash=${data.hash_result}`;
    });
}

/* eslint-disable no-undef */

window.initializePreview = () => {
    papaya.Container.startPapaya();
    updateUserJobsTable();
    $("#preview_menu")
        .on("shown.bs.collapse", () => {
            papaya.Container.resizePapaya();
        })
        .on("show.bs.collapse", () => {
            papaya.Container.resizePapaya();
        });
};

/* eslint-enable no-undef */
