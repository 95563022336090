import $ from "jquery";
import "../shared/page-view.js";
import * as nifti from "nifti-reader-js";
import {
    showFailureMessage,
    showSuccessMessage,
    showWarningMessage,
} from "../shared/message.js";


function makeError(onFailure, message) {
    return () => {
        let text = "Invalid NIFTI file";
        let errorMessage = message;
        if (typeof message === "function") errorMessage = message();
        if (message !== undefined) text = `${text}<br>${errorMessage}`;
        showFailureMessage(text);
        return onFailure();
    };
}

function cyrb53(view, seed = 0) {
    let h1 = 0xdeadbeef ^ seed;
    let h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < view.length; i++) {
        ch = view[i];
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
    h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
    h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
}

function validateInputFile(elem, file, onSuccess, onFailure) {
    const reader = new FileReader();
    reader.onerror = makeError(
        onFailure,
        () => `Unable to read file ${reader.error}`,
    );
    reader.onload = () =>
        checkNumberOfSubmittedFiles(
            elem,
            () =>
                checkFileSize(
                    elem,
                    file,
                    () =>
                        checkNIFTIResolution(
                            file.name,
                            reader.result,
                            () =>
                                checkUniqueness(
                                    elem.name,
                                    reader.result,
                                    onSuccess,
                                    onFailure,
                                ),
                            onFailure,
                        ),
                    onFailure,
                ),
            onFailure,
        );
    reader.readAsArrayBuffer(file);
}

const FILE_SIZE_MAX = 256 * 1024 * 1024;
const MIN_DIM = 30;
const MAX_DIM = 1280;
const MB = 1048576;
const hash_files = {};

function checkUniqueness(name, content, success, error) {
    const hash = cyrb53(new Uint8Array(content));
    delete hash_files[name];
    const unique = Object.values(hash_files).some((x) => x === hash);
    if (unique) {
        makeError(error, () => "File should be unique")();
    } else {
        hash_files[name] = hash;
        success();
    }
}

function checkNIFTIResolution(_, rawData, onSuccess, onFailure) {
    let data = rawData;
    if (nifti.isCompressed(data)) {
        try {
            data = nifti.decompress(data);
        } catch {
            return makeError(onFailure, "Unable to decompress NIFTI file")();
        }
    }
    if (!nifti.isNIFTI(data)) return makeError(onFailure, "Not a NIFTI file")();
    const niftiHeader = nifti.readHeader(data);

    const ndims = niftiHeader.dims[0];
    if (ndims !== 3 && (ndims !== 4 || niftiHeader.dims[4] !== 1))
        return makeError(onFailure, "Should be a valid 3D NIFTI file")();

    for (let i = 1; i < 4; i++) {
        if (niftiHeader.dims[i] < MIN_DIM) {
            return makeError(
                onFailure,
                `Image resolution is too low to be processed correctly.<br>
        Dimension[${i - 1}] has only ${niftiHeader.dims[i]} samples.<br>
          The minimum number of samples accepted is ${MIN_DIM}.`,
            )();
        }
        if (niftiHeader.dims[i] > MAX_DIM) {
            return makeError(
                onFailure,
                `Image resolution is too high to be processed correctly.<br>
        Dimension[${i - 1}] has ${niftiHeader.dims[i]}
        samples.<br>The maximum number of samples accepted is ${MAX_DIM}.`,
            )();
        }
    }
    return onSuccess();
}

function checkNumberOfSubmittedFiles(elem, onSuccess, onFailure) {
    const file_count = elem.files.length;
    if (file_count === 1) return onSuccess();

    return makeError(
        onFailure,
        `Should upload a single file, but ${file_count} submitted`,
    )();
}

function checkFileSize(elem, file, onSuccess, onFailure) {
    const size = file.size;
    if (size < FILE_SIZE_MAX) return onSuccess();

    return makeError(
        onFailure,
        `File size ${size / MB} exceed the limit of ${FILE_SIZE_MAX / MB} MB.`,
    )();
}

function updateSubmitButton() {
    const elem = $("#upload_submit_button");
    const state =
        $("input[type=file][required]").filter(
            (i, o) => o.value === undefined || o.value === "",
        ).length === 0;
    if (state) {
        $("#step3").show();
        elem.removeClass("disabled").removeClass("invisible");
    } else elem.addClass("disabled");
    elem.prop("disabled", !state);
}

function updateOnFileChange(root) {
    root.find("input[type=file]").bind("change", (e) => {
        const el = e.currentTarget;
        const file = el.files[0];
        const success = () => {
            const e = $(el);
            e.removeClass("is-invalid").addClass("is-valid");
            e.parent().children("label").html(file.name);
            updateSubmitButton();
        };
        const failure = () => {
            const e = $(el);
            e.removeClass("is-valid").addClass("is-invalid").val("");
            e.parent().children(`label[for=${el.id}]`).text(el.placeholder);
            updateSubmitButton();
        };
        validateInputFile(el, file, success, failure);
    });
}

window.initializeSubmit = () => {
    $(".select-pipeline").on("click", (e) => {
        const tgt = $(e.target).data("target");
        if (tgt) window.location = tgt;
    });
    $("#submitPage").pageView({
        current: "INITIALIZE",
        upload: false,
        pipelineName: window.PIPELINE_NAME,
        configs: {},
        actions: {
            DISPLAY_PROGRESS_VALUE: {
                update: (view, payload) => {
                    $(view.elem)
                        .find("#upload_indicator_bar")
                        .width(payload.value);
                },
                render: (view) => {
                    $(view.elem).find("#upload_indicator").show();
                },
            },
            DISPLAY_PIPELINE_FORM: {
                update: (view) => {
                    const _el = $(view.elem);
                    _el.find("#upload_indicator_bar").width(0);
                    const f = _el.find("#pipelineInputs");
                    f.find("input[type=text]").val("");
                    f.find("input[type=number]").val("");
                    f.find("select").val("");
                    f.find("input[type=file]").each((_, fe) => {
                        const ff = $(fe);
                        ff.val("")
                            .parent()
                            .find("label")
                            .html(ff.attr("placeholder"));
                    });
                    _el.find("#step1").show();
                    if (view.config.pipelineName !== "") {
                        _el.find("#step2").show();
                        _el.find(".action-update_default_version").click(
                            (e) => {
                                e.preventDefault();
                                const pn = view.config.pipelineName;
                                const vn = $(
                                    "#pipeline-default-version option:selected",
                                )
                                    .val()
                                    .split("-")[0];
                                $.ajax({
                                    url: `/api/v1.0/pipelines/${pn}/versions/default/${vn}`,
                                    method: "POST",
                                    success: () => {
                                        showSuccessMessage(
                                            ` ${pn} pipeline default version updated ${vn}`,
                                        );
                                        $("#pipelineDefaultVersionModal").modal(
                                            "hide",
                                        );
                                    },
                                    error: () => {
                                        showWarningMessage(
                                            "An error occurred, default version update failure.",
                                        );
                                        $("#pipelineDefaultVersionModal").modal(
                                            "hide",
                                        );
                                    },
                                });
                            },
                        );
                    } else {
                        _el.find("#custom_form").html("");
                        _el.find("#step2").removeClass("active").hide();
                    }
                    _el.find("#step3").removeClass("active").hide();
                    _el.find("#step4").removeClass("active").show();
                    _el.find("#upload_indicator").hide();
                    $(".custom-file-input").removeClass("is-valid is-invalid");
                    _el.find("#upload_submit_button")
                        .addClass("disabled")
                        .prop("disabled", true);
                },
            },
            INITIALIZE: {
                create: (view) => {
                    const _el = $(view.elem);
                    _el.find("#upload_form").submit(function (e) {
                        e.preventDefault();
                        try {
                            const form = $(this);
                            const data = new FormData(form[0]);
                            const xhr = new XMLHttpRequest();
                            xhr.responseType = "json";
                            xhr.addEventListener("load", () => {
                                view.config.uploading = true;
                            });
                            xhr.addEventListener("loadend", function () {
                                const uploaded = this.status === 200;
                                $("#upload_indicator_bar").stop();
                                view.config.uploading = false;
                                const msg = Object.hasOwn(
                                    this.response,
                                    "message",
                                )
                                    ? this.response.message
                                    : JSON.stringify(this.response, null, 2);
                                if (uploaded) {
                                    showSuccessMessage(
                                        `Job successfully uploaded: ${msg}`,
                                    );
                                } else {
                                    showWarningMessage(
                                        `Sorry, an error occured: ${msg}`,
                                    );
                                }
                                view.dispatch("DISPLAY_PIPELINE_FORM", {});
                            });
                            xhr.addEventListener("error", function () {
                                view.config.uploading = false;
                                const msg = Object.hasOwn(
                                    this.response,
                                    "message",
                                )
                                    ? this.response.message
                                    : JSON.stringify(this.response, null, 2);
                                showFailureMessage(
                                    `Sorry, an error occurred: ${msg}`,
                                );
                                view.dispatch("DISPLAY_PIPELINE_FORM", {});
                            });
                            xhr.addEventListener("abort", function () {
                                view.config.uploading = false;
                                const msg = Object.hasOwn(
                                    this.response,
                                    "message",
                                )
                                    ? this.response.message
                                    : JSON.stringify(this.response, null, 2);
                                showFailureMessage(
                                    `Sorry, an error occurred: ${msg}`,
                                );
                                view.dispatch("DISPLAY_PIPELINE_FORM", {});
                            });
                            xhr.upload.addEventListener("progress", (e) => {
                                const percent = (e.loaded / e.total) * 100;
                                view.dispatch("DISPLAY_PROGRESS_VALUE", {
                                    value: `${percent}%`,
                                });
                            });

                            xhr.open("POST", "/api/v1.0/jobs/upload", true);

                            /* eslint-disable no-undef */
                            updateAjaxCall(xhr); // FIXME Should we do that ?

                            /* eslint-enableno-undef */

                            xhr.setRequestHeader("Accept", "application/json");
                            xhr.setRequestHeader("Cache-Control", "no-cache");
                            xhr.setRequestHeader(
                                "X-Requested-With",
                                "XMLHttpRequest",
                            );
                            $(".custom-file-input").removeClass(
                                "is-valid is-invalid",
                            );
                            $("#upload_submit_button") // Should probably be a dispatch ... the day I'll understand how it works
                                .addClass("disabled")
                                .prop("disabled", true);
                            view.dispatch("DISPLAY_PROGRESS_VALUE", {
                                value: "0%",
                            });
                            xhr.send(data);
                        } catch (e) {
                            console.log(e);
                        }
                    });
                    updateOnFileChange(_el);
                    view.dispatch("DISPLAY_PIPELINE_FORM", {});
                },
                update: () => {},
                render: () => {},
            },
        },
    });
};
