import $ from "jquery";

$(document).ready(() => {
    $("input[type=checkbox]").click(function() {
        const _this = $(this);
        _this.val(_this.prop("checked") === true ? "1" : "0");
    });
    $("#institution_name").on("input", function() {
        const val = $(this).val();
        if (val === "" || val.length < 2) return;
        $.get(
            "/api/v1.0/institutions",
            { q: val },
            (res) => {
                const dataList = $("#institution_names");
                dataList.empty();
                if (res.results.length) {
                    for (let i = 0, len = res.results.length; i < len; i++) {
                        const opt = $("<option></option>").attr(
                            "value",
                            res.results[i],
                        );
                        dataList.append(opt);
                    }
                }
            },
            "json",
        );
    });
});
