import { showSuccessMessage } from "./message.js";
import $ from "jquery";

window.initializeCookies = () => {
    const COOKIE_CONSENT_CONTAINER = document.getElementById("cookie-consent-container")
    const COOKIE_CONSENT_AGREE_BUTTON = document.getElementById("cookie-consent-agree")

    COOKIE_CONSENT_AGREE_BUTTON?.addEventListener("click", () => {
        $.ajax({
            url: "/cookies/consent",
            type: "POST",
            data: { cookie_consent: "true" },
            success: () => {
                showSuccessMessage("Consent agreements updated");
            },
            error: () => {
                console.log("Failed to apply consent agreements!");
            },
        });
        COOKIE_CONSENT_CONTAINER.hidden = true;
    })
};
