import { GoogleCharts } from "google-charts";
import $ from "jquery";
import worldmapSVG from "../assets/worldmap.svg?raw";

function parsergb(color) {
    const start = color[0] === "#" ? 1 : 0;
    return [0, 2, 4].map(
        (i) => Number.parseInt(color.slice(start + i, start + i + 2), 16) / 255,
    );
}
function scale(value, min, max) {
    return value * (max - min) + min;
}
const sample = {
    log: (v, min, max) => Math.log(v - min) / Math.log(max - min),
    pow: (v, min, max) => (v - min) ** 0.43 / (max - min) ** 0.43,
    linear: (v, min, max) => (v - min) / (max - min),
};
function colorizer(c1, c2, min, max, method) {
    const color1 = parsergb(c1);
    const color2 = parsergb(c2);
    const inter = sample[method];
    return (v) => {
        let value = v;
        if (value < min) value = min;
        if (value > max) value = max;
        const pc = inter(value, min, max);
        const color = [0, 1, 2].map((i) => scale(pc, color1[i], color2[i]));
        return `rgb(${color[0] * 255}, ${color[1] * 255}, ${color[2] * 255})`;
    };
}

function setupGeoChart(id, method = "linear") {
    const parser = new DOMParser();
    const worldmap = parser.parseFromString(worldmapSVG, "image/svg+xml");
    $(`#${id}`).empty().append(worldmap.documentElement);
    $.getJSON("/api/v1.0/stats/countries").done((countries) => {
        const colorize = colorizer("#7092be", "#2a475f", 0, 1000, method);
        for (const country of countries) {
            $(`#c_${country[0]} title`)
                .append(`: ${country[1]}`)
                .parent()
                .attr("fill", colorize(country[1]));
        }
    });
    return () => {};
}

function setupBarChart(id) {
    const series = [["Month", "Total"]];
    const options = {
        title: "Number of jobs per month",
        legend: { position: "none" },
        colors: ["#7092be", "#2a475f"],
        responsive: true,
    };
    function drawBarChart() {
        const container = document.getElementById(id);
        if (!container) return;
        $.getJSON("/api/v1.0/stats/jobs?limit=120", (data) => {
            const datatable = GoogleCharts.api.visualization.arrayToDataTable(
                series.concat(data),
            );
            const chart = new GoogleCharts.api.visualization.ColumnChart(
                container,
            );
            chart.draw(datatable, options);
        });
    }
    GoogleCharts.load(drawBarChart, "corechart");
    return drawBarChart;
}

window.initializeUsers = () => {
    const charts = [setupGeoChart("worldmap"), setupBarChart("jobs")];
    $(window).resize(() => {
        for (const chart of charts) {
            chart();
        }
    });
};
