import $ from "jquery";
import { showWarningMessage } from "../shared/message.js";
import {
    format_date,
    render_date,
    render_guarded_btn_groups,
    render_job_status,
    render_metainfos,
} from "../shared/render-helpers.js";


const ResultsTableConfigs = {
    last_jobs: {
        autoWidth: true,
        processing: false,
        serverSide: true,
        select: false,
        responsive: true,
        ajax: "/api/v1.0/jobs",
        layout: {
            top2End: {
                buttons: [{ extend: "csv", text: "Export to CSV" }],
            },
        },
        columnDefs: [
            {
                targets: "_all",
                defaultContent: "",
            },
            {
                targets: [0],
                visible: true,
                title: "ID",
                type: "num",
                data: "id",
                responsivePriority: -1,
            },
            {
                targets: [1],
                title: "Date",
                type: "str",
                data: "created",
                render: render_date(),
            },
            {
                targets: [2],
                title: "Pipeline",
                type: "str",
                data: "pipeline_name",
                responsivePriority: 2,
            },
            {
                targets: [3],
                title: "Version",
                type: "str",
                data: "version_number",
                responsivePriority: 2,
            },
            {
                targets: [4],
                title: "Files",
                data: "metadata",
                render: render_metainfos(),
                responsivePriority: 4,
            },
            {
                targets: [5],
                title: "Status",
                type: "num",
                data: "status",
                render: render_job_status,
            },
            {
                targets: [6],
                visible: false,
                searchable: false,
                title: "Hash",
                type: "str",
                data: "hash_result",
                name: "hash",
            },
            {
                targets: [7],
                visible: true,
                className: "text-right",
                title: "Actions",
                orderable: false,
                responsivePriority: 3,
                data: null,
                render: render_actions(),
            },
        ],
        order: [[1, "desc"]],
    },
};

function render_actions() {
    const buttons = {
        log: [
            {
                action: "log",
                title: "View Job Details ...",
                icon: "edit",
                className: "btn-outline-success",
            },
        ],
        content: [
            {
                action: "pdf",
                title: "Download PDF Report File",
                icon: "file-pdf",
                className: "btn-outline-danger",
            },
            {
                action: "csv",
                title: "Download CSV Report File",
                icon: "file-csv",
                className: "btn-outline-success",
            },
            {
                action: "zip",
                title: "Download Archive File (ZIP)",
                icon: "file-archive",
            },
            { action: "pre", title: "Preview NII Files", icon: "eye" },
        ],
    };
    return render_guarded_btn_groups(
        [
            [
                (data, full) =>
                    [6, 7].includes(Number(full.status)) && "error_msg" in full,
                ...buttons.content,
                ...buttons.log,
            ],
            [
                (data, full) => [6, 7].includes(Number(full.status)),
                ...buttons.content,
            ],
            [(data, full) => "error_msg" in full, ...buttons.log],
        ],
        "btn-sm",
        "editor_",
        "btn-outline-info",
    );
}

function download(filename, jobHash) {
    const fileUrl = `/user/download/hash/${jobHash}/${filename}`;
    window.open(fileUrl, "_blank");
}

function previewUserJob(jobHash) {
    window.open(`/user/preview?kiosk=True&hash=${jobHash}`, "preview");
}

function showLogs(job) {
    const meta = JSON.stringify(JSON.parse(job.metadata), null, "");
    $("#error_msg").val(job.error_msg);
    $("#error_msg").scrollTop(0);
    $("#job_id").val(job.id);
    $("#status").val(job.status);
    $("#node_name").val(job.node_name);
    $("#hash_result").val(job.hash_result);
    $("#pipeline_name").val(job.pipeline_name);
    $("#version_number").val(job.version_number);
    $("#metadata").val(meta);
    $("#created").val(format_date(job.created));
    $("#launched").val(format_date(job.launched));
    $("#finished").val(format_date(job.finished));
    $("#retry").val(job.number);
    $("#purged").val(job.purged);
    $("#LogModal").modal("show");
}

window.initializeResults = () => {
    $.fn.dataTable.ext.errMode = "none";
    $.fn.dataTable.Buttons.defaults.dom.button.className =
        "btn btn-sm btn-outline-info";
    const table = $("#resultsPage #jobs_table").DataTable(
        ResultsTableConfigs.last_jobs,
    );
    table.on("error.dt", () => {
        window.location.reload();
    });
    table.on("draw", function () {
        $(this).find('[data-toggle="tooltip"]').tooltip();
        $(this).find('[data-toggle="popover"]').popover();
    });
    table.column("Hash").visible = false;
    setInterval(() => {
        table.ajax.reload(null, false);
    }, 30000);
    const btns = {
        pdf: (hash) => download("report.pdf", hash),
        csv: (hash) => download("report.csv", hash),
        zip: (hash) => download("archive.zip", hash),
        pre: (hash) => previewUserJob(hash),
        log: (_, job) => showLogs(job),
    };
    for (const k in btns) {
        $("#jobs_table tbody").on("click", `.editor_${k}`, (e) => {
            const tr = $(e.target).closest("tr");
            const data = table.row(tr).data() || table.row(e.target).data();
            if (data !== undefined) btns[k](data.hash_result, data);
            else showWarningMessage(`no data found for job ${tr[0].id}`);
            return false;
        });
    }
};
