import $ from "jquery";

window.$ = window.jQuery = $;
setupAjaxHeaders(); /* eslint-disable-line no-undef */

import "./theme.js";
import "./message.js";
import "./preview.js";
import "./page-view.js";
import "./render-helpers.js";
import "./cookies.js";

import "./css/style.css"
